const about = {
  aboutPageTitle: 'One account for all things RAK.',
  aboutPageSubTitle:
    'Everything you need to power your IoT product starts here! Your RAK ID seamlessly syncs and secures all your RAK products and services with one set of credentials. To get started, {link} now.',
  aboutPageSubTitleLink: 'Sign up',
  aboutPageProductsTitle: 'RAK ecosystem',
  aboutPageProductsSubTitle: 'Find out more about products.',
  aboutPageProductDescriptionWisDM: 'All-in-one access to your IoT networks',
  aboutPageProductDescriptionWisHM: 'Easily connect your gateway to Helium',
  aboutPageProductDescriptionWisNode: 'Control your IOT devices from one place',
  aboutPageProductDescriptionWisToolBox: 'Quickly configure and deploy your RAK end devices',
  aboutPageProductDescriptionMntdBox: 'Setup and Monitor your Helium Hotspot',
  aboutPageHeaderSignUp: 'Create your RAK ID',
  aboutPageHeaderSignIn: 'Sign in',
  releaseDownloadCenterBox: 'Release Download Center',
};

export default about;
