import { createTheme } from '@material-ui/core/styles';

const customTheme = {
  palette: {
    primary: {
      main: 'rgba(0, 106, 198, 1)',
      getMainRgba: (opacity) => `rgba(0, 106, 198, ${opacity})`,
      dark: 'rgba(0, 106, 198, 0.5)',
      light: 'rgba(0, 106, 198, 0.2)',
      lightest: 'rgba(0, 106, 198, 0.1)',
    },
    secondary: {
      main: '#3AD675',
      dark: '#289F5A',
      light: '#61FF9D',
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
  custom: {
    blackColor: '#000',
    whiteColor: '#FFF',
    backgroundColor: '#F5F7F9',
    errorColor: '#F13456',
    errorLight: 'rgba(241, 52, 86, 0.1)',
    errorDark: 'rgba(241, 52, 86, 0.5)',
    infoColor: '#1669E5',
    infoBlur: '#006ac6',
    infoLight: 'rgba(22, 105, 229, 0.1)',
    successColor: '#289F5A',
    successLight: 'rgba(58, 214, 117, 0.1)',
    textColor: '#555555',
    darkColor: '#202020',
    warningColor: '#FCCC6F',
    greyColor: '#DEDEDE',
    getDarkRgbaColor: (opacity) => `rgba(32, 32, 32, ${opacity})`,
  },
  products: {
    wisdm: '#134AC6',
    wishm: '#5D16A6',
    wisnode: '#009FEF',
    wistoolbox: '#31B468',
    mntd: '#B08541',
  },
};

const theme = createTheme({
  palette: {
    primary: {
      main: customTheme.palette.primary.main,
      getMainRgba: customTheme.palette.primary.getMainRgba,
      dark: customTheme.palette.primary.dark,
      light: customTheme.palette.primary.light,
      lightest: customTheme.palette.primary.lightest,
    },
    secondary: {
      main: customTheme.palette.secondary.main,
      light: customTheme.palette.secondary.light,
      dark: customTheme.palette.secondary.dark,
    },
  },
  breakpoints: customTheme.breakpoints,
  custom: {
    blackColor: customTheme.custom.blackColor,
    whiteColor: customTheme.custom.whiteColor,
    backgroundColor: customTheme.custom.backgroundColor,
    errorColor: customTheme.custom.errorColor,
    errorLight: customTheme.custom.errorLight,
    errorDark: customTheme.custom.errorDark,
    infoColor: customTheme.custom.infoColor,
    infoBlur: customTheme.custom.infoBlur,
    infoLight: customTheme.custom.infoLight,
    successColor: customTheme.custom.successColor,
    successLight: customTheme.custom.successLight,
    textColor: customTheme.custom.textColor,
    darkColor: customTheme.custom.darkColor,
    warningColor: customTheme.custom.warningColor,
    greyColor: customTheme.custom.greyColor,
    getDarkRgbaColor: customTheme.custom.getDarkRgbaColor,
  },
  products: {
    ...customTheme.products,
  },
});

export default theme;
