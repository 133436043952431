import { call, delay, put, select } from 'redux-saga/effects';
import RoutePaths from '../../app/routes/routePaths';
import AuthSelectors from '../../pages/Auth/selectors';
import API_REQ from '../apiActions';
import Auth from '../apiEndpoints/Auth';
import fetchUserProfile from './fetchUserProfile';
import redirect from '../utils/redirect';
import cache from '../utils/cache';
import history from '../../app/redux/configureBrowserHistory';

const { REACT_APP_RAK_PORTAL_CLIENT_ID } = process.env;

export default function* consentLogin() {
  const isRAKPlatform = yield select(AuthSelectors.isRAKPlatform());
  // Todo for wistool
  const isWisToolPlatform = yield select(AuthSelectors.isWisToolPlatform());
  const refreshToken = cache.getRefreshToken();

  // Login directly for RAK ID portal
  if (isRAKPlatform) {
    yield call(fetchUserProfile);
    redirect(RoutePaths.UserProfile._());
    return;
  }

  yield put(API_REQ.AUTH.CACHED_TOKEN_VERIFIED(false));

  const consentRequestUrl = refreshToken ? Auth.consentLogged(refreshToken) : Auth.consent();

  window.location.replace(consentRequestUrl);

  yield delay(5000);

  if (isWisToolPlatform) {
    yield put(API_REQ.AUTH.SET_CLIENT_ID({ clientId: REACT_APP_RAK_PORTAL_CLIENT_ID }));
    yield call(fetchUserProfile, REACT_APP_RAK_PORTAL_CLIENT_ID);
    history.push(RoutePaths.UserProfile._());
  }

  yield put(API_REQ.AUTH.CACHED_TOKEN_VERIFIED(true));
}
